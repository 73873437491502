<template>
  <div class="wrapper">
    <div class="wrapper__container">
    <jublia-header />
      <div class="container">
        <jublia-nav-step :trad="'treatment.calculator.title'" />
   
      
      
        <div v-if="showResult" class="container-result">
          <div v-if="nbDays == 0">
            <jublia-p  class=" mb-14 ">{{ $t('treatment.calculator.result.error') }}</jublia-p>
            <router-link to="step-calculator"
            class="no-underline mt-8 "
            style="margin-top: 5rem;"
          >
            <jublia-button class="uppercase">
              {{ $t('error-form.button') }}
            </jublia-button>
          </router-link>
          </div>
          <div v-else>
            <jublia-h3 class="mb-6  title ">{{
              $t('treatment.calculator.result.title')
            }}</jublia-h3>
            <jublia-p class="text">
              {{ $t('treatment.calculator.result.prescriptedStart') }}
               <span class="text-xl DINBold text-black">{{ nbBottles }}</span>
              <span   
                v-html="$t('treatment.calculator.result.prescriptedMiddle')"
              />
              <span class="text-xl  DINBold text-black">{{ nbMonthsPrescripted }}</span>
              <span v-html="$t('treatment.calculator.result.prescriptedEnd')" />
            </jublia-p>
            <jublia-p class="mt-8 text">
              {{ $t('treatment.calculator.result.text') }} 
              <span class="text-xl DINMedium  text-black">
              {{ nbDays }} {{ $t('treatment.calculator.result.days') }}
            </span>
            </jublia-p>
           
          </div>
         
          <jublia-result-zone v-if="nbDays > 0" />
        </div>
      
          <div class="w-full md:mx-auto md:w-4/5 lg:w-2/3"  v-if="showResult && nbDays > 0">
           <jublia-button
            
             @click="go();"
              bgpurple
              class="w-full flex justify-between items-center mt-2"
            >
              <div class="text-xl font-bold flex justify-between items-center  mr-3 ">
                 {{ $t('user-settings.back') }}
              </div>
               
            </jublia-button>
           
          </div>
        <!--</router-link>-->
        
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaBreadcrumb from '@/components/breadcrumb/jublia-breadcrumb'
//import JubliaP from '~/components/text/jublia-p'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import JubliaSelectZone from '@/components/select-zone/jublia-select-zone'
import JubliaResultZone from '@/components/result-zone/jublia-result-zone'
import JubliaPrescriptionDuration from '@/components/prescription-duration/jublia-prescription-duration'
import {maxLength, minLength} from '@vuelidate/validators'
import { useStore} from 'vuex'
import { useI18n } from "vue3-i18n";
import { reactive, ref } from "vue";
import {useRouter  } from "vue-router";
export default {
  components: {
    'jublia-header':JubliaHeader,
    'jublia-nav-step':JubliaNavStep,
   'jublia-select-zone': JubliaSelectZone,
    'jublia-button':JubliaButton,
 //JubliaH1,
    'jublia-p':JubliaP,
    'jublia-h3':JubliaH3,
   'jublia-breadcrumb': JubliaBreadcrumb,
    'jublia-result-zone':JubliaResultZone,
   'jublia-prescription-duration': JubliaPrescriptionDuration
  },
  data() {
    return {
      showSelector: false,
      step:1,
      showPrescripted: false,
      showResult: true,
      s:false,
      user: {},

    }
  },
   setup () {
      const store= useStore()
    // store.dispatch('treatment/schemaSelection')
    return { }
  },
 
  computed: {
    ...mapState({
         schemaSelection: (state) => state.treatment.schemaSelection,
        nbDays: (state) => state.treatment.nbDays,
       typeUser: (state) => state.login.loggedType,
      nbBottles: (state) => state.treatment.nbBottles,
      nbMonthsPrescripted: (state) => state.treatment.nbMonthsPrescripted,
     
    }),
     ...mapActions({
      fn: 'treatment/schemaSelection'
      
    }),
  },
   created: function () {
    
   if(!!sessionStorage.getItem('user')){
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
   }
  
  },
 mounted() {
    
    this.$store.dispatch('treatment/schema',this.schemaSelection)
 },
  async fetch({ store }) {
  },
  methods: {
    ...mapActions({
      greatToesInfected: 'treatment/greatToesInfected',
      smallToesInfected: 'treatment/smallToesInfected'
    }),
    reset({ store }) {
      this.greatToesInfected(0)
      this.smallToesInfected(0)
       
      this.showSelector = true
      this.showPrescripted = false
      this.showResult = false
      this.s = false
      this.step=1
      this.$store.dispatch('treatment/init')
    },
    displayPrescripted() {
      this.showSelector = false
      this.showPrescripted = true
      this.s = false
    },
    display() {
      this.showSelector = false
      this.showPrescripted = false
      this.s = true
    },
    go(){
      let a=`/menu`
     
     this.$router.push(a)
    },
    displayResult() {
      this.showSelector = false
      this.showPrescripted = false
      this.showResult = true
     // this.step=2
    }
  },
  validations: {
    phoneInput: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
    name: {
      minLength: minLength(0),
      maxLength: maxLength(19)
    },
  }
}
</script>
<style lang="postcss" scoped>
.selector {
  @apply absolute;
}


.li::marker {
  
  color: #00B4CB;
  
}
.text{
  font-size: 18px;
}
.container {
  min-height: 39rem;
  @media all and (min-width: 768px) {
   
   /*min-height: 35rem;*/
  }
}
.puce-selected {
  @apply block rounded-full bg-jublia-blue-light text-lg border border-jublia-bluest text-jublia-bluest flex justify-center items-center cursor-pointer;
  width: 20px;
  height: 20px;
  padding-top: 4px;
}
</style>
